import React from 'react';
import Image from 'next/image';
/* Sections */
import * as Sections from '@components/headers';
/* Helpers */
import { capitalizeFirstChar } from '@helpers/string';

function resolveHeaders(header: any) {
  const SectionComponent = header._type === 'component'
    ? Sections[capitalizeFirstChar(header.headers[0]._type)]
    : Sections[capitalizeFirstChar(header._type)];
  if (SectionComponent) {
    return SectionComponent;
  }
  return null;
}

interface IHeader {
  _type: string,
  _key: string,
  headerColor: 'white' | 'black' | 'orange' | 'grey',
  headerSpacing: 'md' | 'l',
  headerBottomSpacing: boolean,
  headers: any,
  backgroundColorHeader: string
}

const styling = {
  decorativeBarImage: sq`
    absolute
    top-0
    left-0
    bottom-0
    right-0
    rounded-tl-[1rem]
    rounded-tr-[1rem]

    md:rounded-tl-[1.5rem]
    md:rounded-tr-[1.5rem]

    2xl:rounded-tl-[2rem]
    2xl:rounded-tr-[2rem]
  `,
  decorativeBarOverlay: sq`
    absolute
    bottom-0
    w-full
    h-[calc(100%-0.25rem)]
    rounded-tl-[1rem]
    rounded-tr-[1rem]

    md:h-[calc(100%-0.5rem)]
    md:rounded-tl-[1.5rem]
    md:rounded-tr-[1.5rem]

    2xl:rounded-tl-[2rem]
    2xl:rounded-tr-[2rem]
  `,
};

// TODO: Add type/interface for header
export default function HeaderBox(props: any) {
  const { headers, locale, theme, backgroundColorHeader } = props;

  if (!Array.isArray(headers)) {
    return null;
  }

  return (
    <>
      { headers.map((header: IHeader) => {
        const HeaderComponent = resolveHeaders(header);
        const headerProps = header._type === 'component'
          ? header.headers[0]
          : header;
        return !HeaderComponent
          ? <div key={ header._key } className={ 'text-[#f00] text-[2rem]' }>
            { `Missing header ${ header._type } ${ header._type === 'component' ? `(${ headerProps._type })` : '' }` }
          </div>
          : (
            <header key={ header._key } className={
              sq`
                bg-primary-50
                rounded-bl-[2rem]
                rounded-br-[2rem]
              `
            }>
              <HeaderComponent
                { ...headerProps }
                theme={ theme }
                locale={ locale }
                backgroundColorHeader={ backgroundColorHeader }
              />
              {
                headerProps.showArc &&
                <div className='relative h-6 md:h-10 -mb-6 md:-mb-10'>
                  <div className='absolute top-0 bottom-0 left-0 right-0'></div>
                  <Image
                    src={ `/assets/domain-bars/${ theme }.svg` }
                    className={ styling.decorativeBarImage }
                    alt='Decorative bar'
                    fill={ true }
                    sizes='(min-width: 1280px) 1440px, (min-width: 768px) 750px, 640px'
                    priority={ true }
                    unoptimized={ true }
                  />
                  <div className={ `${ styling.decorativeBarOverlay } ${ backgroundColorHeader }` } />
                </div>
              }
            </header>
          );
      })}
    </>
  );
}
